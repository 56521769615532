/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */


// import React from "react"
// import { GlobalProvider} from "./src/context/GlobalContext";
// import * as Survey from "survey-react";

const { StylesManager } = require("survey-react")

StylesManager.applyTheme("modern");
const React = require("react")
const { GlobalProvider } = require("./src/context/GlobalContext")


exports.shouldUpdateScroll = ({
                                  routerProps: { location },
                                  getSavedScrollPosition,
                              }) => {
    const { pathname } = location
    // list of routes for the scroll-to-top-hook`
    const scrollToTopRoutes = [`/`, `/the-green-initiative`, `/es/iniciativa-green`, `/website`, `/es/sitios-web`, `/application`, `/es/aplicaciones`, `/online-shop`, `/es/tiendas-online`, `/packages`,`/es/paquetes`, `/branding`, `/es/branding`, `/cms-the-berry`, `/es/cms-the-berry`, `/portfolio`, `/es/portafolio`, `/marketing`, `/es/márketing`, `/contact`, `/es/contacto`, `/privacy-policy`,`/es/política-de-privacidad`, `/faq`, `/es/faq`, `/questionnaire`,`/es/cuestionario`, `/questionnaire_success`, `/questionnaire_error`]
    // if the new route is part of the list above, scroll to top (0, 0)
    if (scrollToTopRoutes.indexOf(pathname) !== -1) {
        document.querySelector("#gatsby-focus-wrapper").scroll(0,0)
    }

    return false
}

exports.wrapRootElement = ({ element }) => (
    <GlobalProvider>{element}</GlobalProvider>
)
