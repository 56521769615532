import React, {createContext, useState} from 'react';

const GlobalContext = createContext({
    additionalPackages: [],
    setAdditionalPackages: () => {},
})

function GlobalProvider({children}) {
    const [additionalPackages, setAdditionalPackages] = useState([])
    const setGlobalAdditionalPackages = (packages = []) => {
        setAdditionalPackages(packages)
    }
    return (
        <GlobalContext.Provider value={{
            additionalPackages,
            setAdditionalPackages: setGlobalAdditionalPackages,
        }}>
            {children}
        </GlobalContext.Provider>
    );
}

export default GlobalContext;
export { GlobalProvider }