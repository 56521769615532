module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Green Flamingo","short_name":"GreenFlamingo","start_url":"/","background_color":"#8CA668","theme_color":"#8CA668","display":"minimal-ui","icon":"src/images/gf-favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"1f6a86d85bb6791a91079ab8ac2fdd7f"},
    },{
      plugin: require('../plugins/berry-plugin/gatsby-browser.js'),
      options: {"plugins":[],"webFrontId":"FG9OW2EJ4B16J0G0YL6K5X32EOBZYYKKUHM8RWBM"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["Calibri"],"urls":["/fonts/fonts.css"]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
