// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-application-js": () => import("./../../../src/templates/application.js" /* webpackChunkName: "component---src-templates-application-js" */),
  "component---src-templates-branding-js": () => import("./../../../src/templates/branding.js" /* webpackChunkName: "component---src-templates-branding-js" */),
  "component---src-templates-cms-the-berry-js": () => import("./../../../src/templates/cms-the-berry.js" /* webpackChunkName: "component---src-templates-cms-the-berry-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-faq-js": () => import("./../../../src/templates/faq.js" /* webpackChunkName: "component---src-templates-faq-js" */),
  "component---src-templates-homepage-js": () => import("./../../../src/templates/homepage.js" /* webpackChunkName: "component---src-templates-homepage-js" */),
  "component---src-templates-marketing-js": () => import("./../../../src/templates/marketing.js" /* webpackChunkName: "component---src-templates-marketing-js" */),
  "component---src-templates-online-shop-js": () => import("./../../../src/templates/online-shop.js" /* webpackChunkName: "component---src-templates-online-shop-js" */),
  "component---src-templates-packages-js": () => import("./../../../src/templates/packages.js" /* webpackChunkName: "component---src-templates-packages-js" */),
  "component---src-templates-portfolio-list-js": () => import("./../../../src/templates/portfolio_list.js" /* webpackChunkName: "component---src-templates-portfolio-list-js" */),
  "component---src-templates-privacy-policy-js": () => import("./../../../src/templates/privacy-policy.js" /* webpackChunkName: "component---src-templates-privacy-policy-js" */),
  "component---src-templates-questionnaire-js": () => import("./../../../src/templates/questionnaire.js" /* webpackChunkName: "component---src-templates-questionnaire-js" */),
  "component---src-templates-the-green-initiative-js": () => import("./../../../src/templates/the-green-initiative.js" /* webpackChunkName: "component---src-templates-the-green-initiative-js" */),
  "component---src-templates-website-js": () => import("./../../../src/templates/website.js" /* webpackChunkName: "component---src-templates-website-js" */)
}

